import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import FaqStructuredData from "../../components/seo/FaqStructuredData";
import LocalBusinessStructuredData from "../../components/seo/LocalBusinessStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import HomeInfoBoxes from "../../components/HomeInfoBoxes";
import MediaLogos from "../../components/MediaLogos";

const Kreditrechner = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                showCalc={true}
                h1Title={"Kreditrechner - Vergleiche deine Hausbank mit vielen weiteren Banken"}
                calcCTA={"Finanzierung berechnen"}
            />
            <Cooperation hasGreyBg hasMarginTop />
            <HomeInfoBoxes hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Article hasMarginTop>
                <h2>Kreditrechner</h2>
                <p>
                    Wer große Pläne hat, braucht meist auch die entsprechenden Finanzmittel. Ein Kredit muss also her.
                    Aber mit welchen Rahmenwerten sollte man in die Verhandlung mit der Bank gehen? Welche Laufzeit ist
                    die beste? Und bei welcher Bank in Österreich bekommt man den besten{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    ?
                </p>
                <p>
                    Unser Kreditrechner liefert in all diesen Fragen erste Orientierungswerte, mit denen du abschätzen
                    kannst, zu welchen Konditionen du eine Finanzierung erhalten wirst. Wie unser Kreditrechner
                    funktioniert, erklären wir dir im folgenden Beitrag Schritt für Schritt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie funktioniert der Kreditrechner?</h2>
                <p>
                    Mit dem Kreditrechner von miracl berechnest du einfach und schnell die wichtigsten Eckdaten deines
                    Wunschkredits. Einfach den gewünschten Kreditbetrag festlegen, die gewünschte Laufzeit einstellen,
                    zwischen fixem und variablem Zins wählen und der Rechner erstellt dein erstes individuelles Angebot.
                    Dabei bekommst du auch bereits einen ersten Vorgeschmack davon, wie hoch deine monatliche Rate sein
                    könnte. Dabei handelt es sich um ein erstes Vorangebot, mit dem du bereits online deine
                    Wunschfinanzierung prüfen kannst – unverbindlich und kostenlos!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wozu ist der Kreditrechner gut?</h2>
                <p>
                    Eines solltest du bei der Verwendung von Kreditrechnern immer bedenken: Auf dem Weg zu einer
                    kostengünstigen Finanzierung stellen sie lediglich eine erste Etappe dar. Und zwar deswegen, weil du
                    mit ihrer Hilfe lediglich standardisierte Angebote vergleichen kannst. Die konkreten
                    Grenzkonditionen einzelner Banken, bei denen du deinen Kredit in einem weiteren Schritt beantragen
                    kannst, können dabei im Detail nicht berücksichtigt werden. Etwa wird dir deine Hausbank zwar
                    Nachlässe bei einmalig fälligen Gebühren gewähren, jedoch kaum bei den Kreditkonditionen als
                    solchen. Es gilt daher, eine Bank zu finden, deren Geldpolitik offensiv genug ist, um eine möglichst
                    günstige Verhandlungsbasis zu schaffen. Das ist nur mit einer umfassenden und vor allem
                    individuellen Beratung möglich - unsere Finanzierungsspezialisten:innen helfen dir hier gerne
                    weiter!
                </p>
                <hr />

                <h2>Was macht einen günstigen Kredit aus?</h2>
                <p>
                    Du siehst also: Ein Online-Kreditrechner ist ein hilfreiches Instrument, wenn es darum geht, die
                    voraussichtlichen Konditionen eines Kredits abzuschätzen. Doch um den passenden Kredit zu finden,
                    sind natürlich noch weitere Aspekte von entscheidender Bedeutung. Dazu zählen:
                </p>
                <ul>
                    <li>eine leistbare Monatsbelastung, je nach Höhe des monatlichen Einkommens</li>
                    <li>umfangreiche Laufzeit, denn je länger die Laufzeit desto kleiner die Rate</li>
                    <li>Rückzahlungsmöglichkeiten, die sich flexibel gestalten lassen</li>
                    <li>angemessene Sicherheiten, damit du dein Risiko klein halten kannst</li>
                    <li>günstigster Zins – gemessen an der eigenen Bonität</li>
                </ul>
                <p>
                    Die Zinsen, die im Rahmen eines Kredites fällig werden, sind von mehreren Variablen abhängig.
                    Zunächst ist der Referenzzinssatz zu berücksichtigen – der Zins, dessen Erträge Banken für ihre
                    Tätigkeiten am Finanz- bzw. Kapitalmarkt einsetzen. Hinzu kommt ein Zuschlag, dessen Höhe sowohl von
                    deiner Bonität als auch von der Bonität der Bank abhängt – die sogenannte Marge. Von deiner
                    individuellen Risikobereitschaft sowie dem augenblicklichen Zinsniveau hängt es schließlich ab, ob
                    du dich für eine Fixzinsbindung oder für variable Zinsen entscheidest.
                </p>
                <p>
                    Ist das Zinsniveau aktuell niedrig, so kann es empfehlenswert sein, einen Fixzinskredit zu
                    beantragen. Dieser bietet dir über seine gesamte Laufzeit hinweg die größtmögliche
                    Planungssicherheit. Zugleich musst du dir jedoch darüber im Klaren sein, dass ein fixer Zinssatz
                    auch von einem potenziell sinkenden Zinsniveau am Finanzmarkt unberührt bleibt – du in diesem Fall
                    also Verluste machst. Rechnest du also damit, dass das Zinsniveau während der Laufzeit deines
                    Kredits konstant bleiben oder sogar sinken wird, dann solltest du eine variable Verzinsung in
                    Erwägung ziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wir kontaktieren dich auf Wunsch und besprechen mit dir alle Details.</h2>
                <p>Mit miracl profitierst du gleich mehrfach:</p>
                <ul>
                    <li>Bankenvergleich und beste Konditionen</li>
                    <li>Kostenlose und unverbindliche Beratung</li>
                    <li>Schnell und unkompliziert</li>
                </ul>
                <p>
                    Mit unserem Kreditrechner bekommst du online nicht nur ein kostenloses Vorangebot für deinen
                    Wohntraum. Unsere Experten nehmen sich auch Zeit, um die richtige Finanzierung für dein Traumprojekt
                    zu finden. Wir sind nämlich keine Bank, sondern ein Team von unabhängigen Experten, die täglich
                    unterschiedlichste Kredite vergleichen und an Kunden vermitteln. Und wenn du es dir wünscht,
                    kontaktieren wir dich gerne auch telefonisch, um alle Details zu deinem Kredit-Angebot mit dir zu
                    besprechen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum sollte ich Kreditangebote vergleichen?</h2>
                <p>
                    Jede Bank und jedes Kreditinstitut folgt einer eigenen Geschäftspolitik. Allein in Österreich
                    agieren mehr als 100 Banken und Kreditinstitute, weshalb es für Einzelpersonen nahezu unmöglich ist,
                    sämtliche Angebote, jeden Zins und jede Rate im Kopf zu haben – oder gar objektiv vergleichen zu
                    können. Außerdem ist es oft notwendig, gewisse Faktoren wie Vermögen, existierende monatliche
                    Belastungen, monatliches Einkommen und auch Art und Ort des geplanten Projekts zu kennen, bevor ein
                    Konzept für die Finanzierung wirklich steht.
                </p>
                <p>
                    Genau hier setzen wir mit miracl an. Mit uns kannst du nicht nur deine Träume eingehend besprechen,
                    mit uns profitierst du auch von einem echten, unabhängigen Vergleich und erhältst noch in derselben
                    Woche Angebote für deinen Kredit.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie viel kann ich im Monat für einen Kredit zahlen?</h2>
                <p>
                    Bei der Frage, wie hoch die monatliche Rate maximal sein sollte, kann man sich an bewährte Regeln
                    halten. Wer also gerade versucht, einen Kredit samt Zins zu berechnen, der sollte sich an seinem
                    monatlichen Einkommen orientieren. Im Regelfall sollten zwischen 30 % und 35 % des Einkommens für
                    die Tilgung eines Kredits zur Verfügung stehen.
                </p>
                <p>
                    Mit unserem Kreditrechner siehst du mit nur wenigen Klicks sofort, wie die monatliche Rate grob
                    aussehen wird. Der Kreditrechner nimmt dafür deinen gewünschten Kreditbetrag und deine gewünschte
                    Laufzeit als Parameter, um dir schnell und direkt darüber Auskunft zu geben, wie hoch die monatliche
                    Rate ausfallen könnte.
                </p>
                <p>
                    Gut zu wissen: Mit Juli 2022 traten neue Regeln für Immobilienkredite in Kraft, die eine Überhitzung
                    des Marktes verhindern sollen und die auch die monatliche Rate betreffen. Banken müssen bei der
                    Vergabe von Krediten nun darauf achten, dass diese höchstens 40 % des monatlichen Nettoeinkommens
                    ihrer Kund:innen ausmacht. Zudem müssen Kreditnehmer:innen 20 % des Kaufpreises (inkl. Nebenkosten)
                    in Form von Eigenkapital in die Finanzierung einbringen. Darüber hinaus wurde auch für die Laufzeit
                    ein neuer Höchstwert festgelegt: Sie darf nun maximal 35 Jahre betragen.
                </p>
                <hr />

                <h2>Mit welchen Nebenkosten muss ich bei der Kreditaufnahme rechnen?</h2>
                <p>
                    Sobald man einen Immobilienkredit aufnimmt, muss man mit Nebenkosten rechnen. Die wichtigsten Kosten
                    sind dabei die Maklerprovision, die Grunderwerbssteuer, Notar- oder Anwaltskosten sowie
                    Finanzierungskosten. Unabhängig davon, ob und wie die Zinsen sich verändern werden, ist der
                    Kaufpreis der Immobilie also nicht alles, was von dir an Kapital aufgebracht werden muss. Folgende
                    Nebenkosten erwarten dich:
                </p>
                <ul>
                    <li>
                        Die Maklerprovision beträgt ca. 4% des Kaufpreises und kann nur in seltenen Fällen eingespart
                        werden, wenn du keine Makler:in benötigst.
                    </li>
                    <li>
                        Für die Eintragung des Eigentumsrechtes in das Grundbuch ist auch eine Gebühr zu zahlen. Hier
                        zahlst du mindestens 1,1% des Kaufpreises und eine Eingabegebühr von 44 Euro. Wenn der Kauf
                        mithilfe der Bank finanziert wird, sind darüber hinaus noch 1,2% des Pfandbetrags zu bezahlen.
                        Es empfiehlt sich, den Antrag auf Eintragung online zu stellen, um sich zumindest hier Kosten zu
                        sparen.
                    </li>
                    <li>
                        Für jede Liegenschaft ist außerdem eine Grunderwerbssteuer von 3,5% des Grundstückswerts zu
                        bezahlen.
                    </li>
                    <li>
                        Die Notar- oder Anwaltskosten sind ebenfalls unvermeidbar, genauso wie die Finanzierungskosten,
                        wenn du einen Kredit bei einer Bank oder einer Bausparkasse aufnimmst. Hier kannst du mit
                        maximal 3% des Kreditvolumens rechnen.
                    </li>
                </ul>
                <p>
                    Last but not least: Vergiss nicht die Kosten, die dich nach dem Kauf erwarten. Denn neben den
                    bereits genannten Kostenpunkten solltest du auch die nach dem Kauf anfallenden Kosten
                    berücksichtigen und über genügend Kapital für den Erhalt der Immobilie sowie die Deckung der
                    laufenden Kosten verfügen. Hierzu zählen vor allem die Betriebskosten für Heizung, Wasser und Kanal,
                    die Müllentsorgung, die Versicherungsprämie für die Immobilie, öffentliche Abgaben sowie etwaige
                    Kosten für Anlagen, die von der gesamten Hausgemeinschaft genutzt werden (etwa Grünflächen, Lift
                    usw.). Berechnungen der Statistik Austria zufolge beliefen sich die durchschnittlichen
                    Netto-Betriebskosten in Österreich 2020 auf 24,96 Euro pro Quadratmeter im Jahr. Auch sind die
                    Betriebskosten im Falle einer gemieteten Immobilie üblicherweise geringer als bei einem Haus bzw.
                    einer Eigentumswohnung.
                </p>
                <p>
                    Eventuell musst du die Immobilie noch sanieren oder renovieren. Denke auch an die Kosten für die
                    Einrichtung deines neuen Zuhauses. Möbel, technische Geräte, Geschirr etc. kosten oft mehr, als man
                    denkt!
                </p>
            </Article>

            <BreadcrumbList page={"kreditrechner"}></BreadcrumbList>
            <FaqStructuredData />
            <LocalBusinessStructuredData />
        </Layout>
    );
};

export default Kreditrechner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["header", "footer", "mortgageCalc", "page.dienstleistungen.kreditrechner", "kreditrechnerFaq"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
